<template>
    <Header></Header>
    <div class="container">
        <div class="page_title m1620">
            <div class="fs15">Color service</div>
            <div class="fs64 fwb">色彩服务</div>
        </div>
        <div class="style_detail m1920 plr30">
            <div class="top m1620">
                <router-link :to="{ name: 'guide'}">
                    <img src="../../assets/static/icon/icon_back2.png" class="icon_back cursor" alt="">
                </router-link>
                <div class="fs16 pt15">{{ styleGuides.enname }}</div>
                <div class="fs95">{{ styleGuides.name }}</div>
                <div class="fs16 pt60 intro">{{ styleGuides.brief }}</div>
                <div class="flex-box rec fs16 cursor" :class="showRec ? 'active' : ''" @click="showRec = !showRec">产品推荐
                </div>
                <div class="rec_box" :class="showRec ? 'show' : ''">
                    <div class="flex-box flex-wrap" v-if="styleGuides.goods">
                        <router-link :to="{ name: 'productDetail', query: { id: item.id } }" class="item"
                            v-for="(item, index) in styleGuides.goods" :key="index">
                            <div class="series" v-if="item.series">{{ item.series.series_name_en }}</div>
                            <div class="name">{{ item.goods_name }}</div>
                            <img :src="item.image" class="pic" alt="">
                        </router-link>
                    </div>
                </div>
            </div>
            <img :src="item" class="style_pic" alt="" v-for="item in styleGuides.pc_images" :key="item.id">
        </div>
    </div>
    <Footer></Footer>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute();
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "风格指南详情_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});

// 接收传递过来的下标
onMounted(() => {
    styleGuide(route.query.id);

})
// 修改下标
onBeforeRouteUpdate((to) => {
    styleGuide(to.query.id);

});

// 推荐产品
let showRec = ref(false);
// 风格指南
let styleGuides = ref({});
const styleGuide = async (id) => {
    try {
        const res = await api.getStyleGuideDeatil({
            id
        }); // 调用接口
        styleGuides.value = res.data;
    } catch (error) {
        console.error(error);
    }
}
</script>


<style lang="less" scoped>
.style_detail {
    padding-top: 48px;

    .icon_back {
        transition: all ease .5s;

        &:hover {
            transform: scale(1.4);
        }
    }

    .top {

        .intro {
            padding-right: 433px;
        }

        .rec {
            padding: 50px 0 30px;
        

            &::after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-width: 5px 8px;
                border-style: solid;
                border-color: transparent transparent transparent #000;
                margin-left: 10px;
                transition: all ease .5s;
            }

            &.active::after {
                transform: rotate(90deg);
                margin-top: 8px;
            }
        }

        .rec_box {
            max-height: 0;
            overflow: hidden;
            transition: max-height ease-in .5s;
            margin-bottom: 80px;

            &.show {
                max-height: 355px;
            }

            .item {
                width: 168px;
                height: 210px;
                border-radius: 12px;
                background: rgba(255, 255, 255, 1);
                padding: 18px 14.4px 0;
                position: relative;
                overflow: hidden;
                margin-right: 10px;
                cursor: pointer;

                .series {
                    font-size: 12px;
                    line-height: 11px;
                    color: #CCCCCC;
                }

                .name {
                    font-size: 16px;
                    color: #000;
                    font-weight: bold;
                    padding: 5px 0 0;
                }

                .pic {
                    width: 192px;
                    height: auto;
                    position: absolute;
                    bottom: -30px;
                    left: 10px;
                    /* right: 30px; */
                    /* margin: auto; */
                    max-width: inherit;
                }
            }
        }
    }

    .style_pic {
        width: 100%;
        display: block;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 1440px) {
    .style_detail {
        .top {
            padding: 0;
        }
    }
}

@media only screen and (max-width: 980px) {
    .style_detail {
        padding: 0;

        .icon_back {
            width: 30px;
            height: 30px;
        }

        .fs16.pt15 {
            font-size: 8px;
        }

        .fs95 {
            font-size: 47.5px;
        }

        .top {
            padding: 0 15px;

            .intro {
                padding: 0;
                font-size: 8px;
                color: rgba(51, 51, 51, .5);
            }

            .rec {
                padding: 30px 0;
            }

            .rec_box {
                margin-bottom: 30px;

               

                .pb180 {
                    padding-bottom: 0;
                }

                .item {
                    width: 90px;
                    height:110px;
                    margin-bottom: 30px;
                    margin-right: 4px;
                   
                    padding: 6px;

                    .series {
                        font-size: 4px;
                    }

                    .name {
                        font-size: 7px;
                    }

                    .pic {
                        width: 80px;
                        height: 86px;
                        bottom: -10px;
                        right: 0;
                        left: inherit;
                        object-fit: cover;
                    }
                }
            }
        }
    }

}</style>