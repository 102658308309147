<template>
    <Header> </Header>
    <div class="container">
        <div class="page_title m1620">
            <div class="fs15">SIGNEO SELECT</div>
            <div class="fs64 fwb">诗珑产品</div>
        </div>
        <div class="flex-box navigator_box m1620">
            <router-link :to="{ name: 'series' }" class="item ">诗珑严选</router-link>
            <router-link :to="{ name: 'illustrate' }" class="item active">施工说明</router-link>
            <router-link :to="{ name: 'formula' }" class="item ">调色配方</router-link>
        </div>
        <div class="flex-box flex-wrap flex-col-start intro_list m1620" v-if="instructionList.length > 0">
            <a :href="item.url" target="_blank" class="intro_item shadow" v-for="(item, index) in instructionList"
                :key="index">
                <img :src="item.image" class="pic" alt="" />
                <div class="info">
                    <div class="col3 fs22 fwb title m-ellipsis-l2">{{ item.name }}</div>
                    <div class="flex-box pt15">
                        <img :src="item.authors.image" class="avatar" alt="" />
                        <div class="flex-grow-1 m-ellipsis fs10 col7 pl10">{{ item.authors.author_name }}</div>
                        <div class="col9 fs10 detail flex-box">查看详情</div>
                    </div>
                </div>
            </a>
        </div>
        <div class="noData" v-else>
            <img :src="emptyImg" alt="">
        </div>
        <div class="page_box">
            <Pagination v-if="instructTotal > instructPageSize" :sumCount="instructTotal" :perCount="instructPageSize"
                @pageTurn="constructionInstructions" class="pagination"></Pagination>
        </div>
    </div>
    <Footer></Footer>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute()
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "施工说明_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});
// 接收传递过来的下标
onMounted(() => {
    constructionInstructions();
})
onBeforeRouteUpdate((to) => {
    constructionInstructions();
});
const emptyImg = JSON.parse(localStorage.getItem("emptyImg"));

// 施工说明
let instructionList = ref([]);
let instructTotal = ref(0);
let instructPageSize = ref(0);
const constructionInstructions = async (e) => {
    try {
        const res = await api.getConstructionInstructions({
            page: e ? e + 1 : 1,
        });
        instructionList.value = [];
        instructionList.value = res.data.data;
        instructPageSize.value = res.data.per_page;
        instructTotal.value = res.data.total;
    } catch (error) {
        console.error(error);
    }
}

</script>

<style lang="less" scoped>
.intro_list {

    .intro_item {
        width: 320px;
        height: 320px;
        border-radius: 50px;
        background: #fefefe;
        margin-right: 15px;
        margin-bottom: 15px;
        box-shadow: 0 3.3px 43px rgba(0, 0, 0, 0.05);

        .pic {
            width: 320px;
            height: 180px;
            display: block;
            object-fit: cover;
            border-radius: 50px 50px 0 0;
        }

        .info {
            padding: 12px 26px 0;

            .title {
                line-height: 30px;
                height: 60px;
            }
        }

        .avatar {
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }

        .detail::after {
            content: "";
            display: block;
            width: 6px;
            height: 10.5px;
            background: url("../../assets/static/icon/icon_arrow-r.png") no-repeat center;
            background-size: 100%;
            margin-left: 7px;
        }
    }
}

input.col3::placeholder {
    color: #858585;
}

@media only screen and (max-width: 980px) {
    .intro_list {
        flex-wrap: wrap;
        justify-content: space-between;
        
        .intro_item {
            width: calc((100vw - 45px)/2);
            height: auto;
            border-radius: 10px;
            margin-right: 0;

            .pic {
                border-radius: 10px 10px 0 0;
                width: 100%;
                height: 90px;
            }

            .info {
                padding: 10px 8px;

                .title {
                    font-size: 10px;
                    line-height: 12px;
                    height: 24px;
                }
            }

            .pt15 {
                padding-top: 5px;
            }

            .avatar {
                width: 20px;
                height: 20px;
            }

            .m-ellipsis,
            .detail {
                font-size: 8px;
            }

            .m-ellipsis {
                padding-left: 3px;
            }

            .detail::after {
                width: 3px;
                height: 6px;
                margin-left: 3px;
            }
        }
    }
}
</style>