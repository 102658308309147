<template>
	<Header></Header>
	<div class="container">
		<div class="page_title m1620">
			<div class="fs15">SIGNEO TOOLS</div>
			<div class="fs64 fwb">诗珑工具</div>
		</div>
		<div class="flex-box navigator_box m1620">
			<div class="flex-box">
				<router-link :to="{ name: 'calculate' }" class="item active">漆量计算</router-link>
				<!-- <router-link :to="{ name: 'shop' }" class="item">门店查询</router-link> -->
				<router-link :to="{ name: 'security' }" class="item">防伪自查</router-link>
			</div>
		</div>
		<div class="m1620">
			<div class="flex-box flex-col-start compute_box">
				<div class="flex-grow-1">
					<div class="fs20 fwb col4b pb20">{{ needKnow.name }}</div>
					<div class="fs12 detail col4b">
						<div class="pb20" v-html="needKnow.detail"></div>
					</div>
				</div>
				<div class="box">
					<div class="content">
						<div class="flex-box">
							<div class="type_box" :class="typeIndex == 1 ? 'col3 fs16 fwb' : 'col34 fs14'" @click="(typeIndex = 1), (litreIndex = 0), (calculateRes.top_coat = 0), (calculateRes.primer = 0), (area = '')">进口乳胶漆</div>
							<div class="type_box" :class="typeIndex == 2 ? 'col3 fs16 fwb' : 'col34 fs14'" @click="(typeIndex = 2), (calculateRes.top_coat = 0), (calculateRes.primer = 0), (area = '')">环保乳胶漆</div>
						</div>
						<div class="flex-box pl10 l_box" :class="typeIndex == 2 ? 'show' : ''">
							<div class="cursor fwb" @click="(litreIndex = 0), calculatePain()" :class="litreIndex == 0 ? 'col3' : 'col34'">5L</div>
							<div class="cursor fwb" @click="(litreIndex = 1), calculatePain()" :class="litreIndex == 1 ? 'col3' : 'col34'">15L</div>
							<div class="cursor fwb" @click="(litreIndex = 2), calculatePain()" :class="litreIndex == 2 ? 'col3' : 'col34'">18L</div>
						</div>
						<div class="title">房间建筑面积(平方米)</div>
						<div class="flex-box">
							<input type="text" class="input fs11" placeholder="请填写" v-model="area" />
							<div class="confirm flex-box flex-center cursor" @click="calculatePain">提 交</div>
						</div>
						<div class="pt60 flex-box tc">
							<div class="flex-grow-1">
								<div class="fs11">
									底漆用量
									<span v-if="typeIndex == 1">(5L/桶)</span>
								</div>
								<div class="pt20 flex-box flex-center flex-col-end fs12">
									<div class="col34">约</div>
									<div class="col3 fs54 fwb line1 plr15">{{ calculateRes && calculateRes.primer ? calculateRes.primer : "0" }}</div>
									<div class="col34">桶</div>
								</div>
							</div>
							<div class="line"></div>
							<div class="flex-grow-1">
								<div class="fs11">
									面漆用量
									<span v-if="typeIndex == 1">(5L/桶)</span>
								</div>
								<div class="pt20 flex-box flex-center flex-col-end fs12">
									<div class="col34">约</div>
									<div class="col3 fs54 fwb line1 plr15">{{ calculateRes && calculateRes.top_coat ? calculateRes.top_coat : "0" }}</div>
									<div class="col34">桶</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<Footer></Footer>
</template>
<script setup>
	import { ref, reactive, onMounted, inject } from "vue";
	const api = inject("api"); // 使用inject来注入接口
	import { useRoute, onBeforeRouteUpdate } from "vue-router";
	const route = useRoute();
	//设置meta和标题
	import { useHead } from "@unhead/vue";
	useHead({
		title: "漆量计算_诗珑",
		meta: [
			{
				name: "description",
				content: "诗珑",
			},
			{
				name: "keywords",
				content: "诗珑",
			},
		],
	});
	// 接收传递过来的下标
	onMounted(() => {
		getNeedKnow();
	});
	onBeforeRouteUpdate((to) => {
		getNeedKnow();
	});
	let needKnow = ref({});
	const getNeedKnow = async () => {
		try {
			const res = await api.needKnow(); // 调用接口
			needKnow.value = res.data;
		} catch (error) {
			console.error(error);
		}
	};
	// 漆量计算
	let area = ref("");
	let typeIndex = ref(1);
	let litreIndex = ref(0);
	let calculateRes = ref({
		top_coat: 0,
		primer: 0,
	}); //计算结果
	const calculatePain = async () => {
		try {
			if (area.value == "") return;
			const res = await api.paintCalculate({
				type: typeIndex.value,
				litres: litreIndex.value == 0 ? "5" : litreIndex.value == 1 ? "15" : "18",
				area: area.value,
			}); // 调用接口
			calculateRes.value = res.data;
		} catch (error) {
			console.error(error);
		}
	};
</script>
<style lang="less" scoped>
	.compute_box {
		padding: 60px 0 70px;

		.line1 {
			line-height: 1;
		}

		.detail {
			padding-right: 50px;
			position: relative;
			z-index: 3;
			max-width: 680px;
			color: rgba(51, 51, 51, 0.8);
		}

		.box {
			position: relative;
			height: 529px;

			&::after {
				content: "";
				display: block;
				width: 172px;
				height: 172px;
				position: absolute;
				top: -52px;
				right: -72px;
				background: url("../../assets/static/icon/icon_compute1.png") no-repeat center;
				background-size: 100%;
				z-index: 0;
			}

			&::before {
				content: "";
				display: block;
				width: 359px;
				height: 334px;
				position: absolute;
				left: -95px;
				bottom: -70px;
				background: url("../../assets/static/icon/icon_compute2.png") no-repeat center;
				background-size: 100%;
				z-index: 0;
			}

			.content {
				width: 500px;
				// height: 526px;
				border-radius: 32px;
				background: #fff;
				// background-size: 100%;
				position: relative;
				z-index: 1;
				padding: 49px 47px 85px;
				border: 1px solid;
				border-image-source: linear-gradient(109.99deg, rgba(189, 255, 188, 0) 3.84%, rgba(255, 211, 219, 0) 92.75%);
				box-shadow: 11px 4px 25px 0px rgba(0, 0, 0, 0.05) inset;
			}

			.type_box {
				margin-right: 12px;
				text-align: center;
				cursor: pointer;
			}

			.l_box {
				height: 0;
				overflow: hidden;
				transition: all ease 0.5s;
				line-height: 30px;

				&.show {
					height: 30px;
					margin-top: 23px;
				}

				div {
					margin-right: 40px;
				}
			}

			.col34 {
				color: #333;
				opacity: 0.4;
			}

			.title {
				font-size: 11px;
				color: #333333;
				margin-bottom: 7px;
				margin-top: 44px;
			}

			.input {
				width: 267px;
				height: 42px;
				background: #fafafa;
				border-radius: 4px;
				padding: 0 15px;
				margin-right: 20px;
			}

			.line {
				width: 1px;
				height: 134px;
				background: #000;
			}

			.confirm {
				width: 118px;
				height: 44px;
				border: 1px solid #000000;
				font-size: 14px;
				border-radius: 100px;
				box-shadow: 0 3.4px 6px rgba(0, 0, 0, 0.1);
				&:hover {
					background-color: #000;
					color: #fff;
					transition: all 0.5s;
				}
			}
		}
	}

	@media only screen and (max-width: 980px) {
		.compute_box {
			padding: 0;
			display: block;

			.flex-grow-1 {
				width: auto;

				.detail {
					padding: 0;
					font-size: 9px;
					color: rgba(51, 51, 51, 0.5);
				}
			}

			.box {
				margin-top: 50px;
				height: 400px;

				&::before {
					left: -12px;
					bottom: 10px;
					width: 200px;
					height: 200px;
				}

				&::after {
					right: -20px;
					top: -30px;
					width: 100px;
					height: 100px;
				}

				.content {
					width: calc(100vw - 47px);
					padding: 40px 30px;
					background-size: 230%;
					border-radius: 18px;

					.input {
						height: 27px;
						font-size: 10px;
					}

					.confirm {
						height: 27px;
						font-size: 10px;
					}

					.pt60 {
						padding-top: 40px;
					}

					.fs11 {
						font-size: 9px;
					}

					.line {
						width: 0.3px;
					}

					.fs54 {
						font-size: 35px;
					}
				}
			}
		}
	}
</style>
